<script>
import { mapGetters } from 'vuex'
import DashBoardIcon from '../assets/DashBoardIcon'
import IssuingEntityIcon from '../assets/IssuingEntityIcon'
// import SettingsIcon from '../assets/SettingsIcon';

import ProductTourTooltip from '../components/ProductTourTooltip'
import { SIGN_OUT } from '../store/actions'

export default {
  name: 'AppHeader',
  components: {
    ProductTourTooltip,
    DashBoardIcon,
    IssuingEntityIcon,
    // SettingsIcon,
  },
  computed: {
    ...mapGetters('tour', ['step']),
  },
  methods: {
    async signOut() {
      console.log('Sign out')
      await this.$store.dispatch(SIGN_OUT)
      this.$router.push('/SignIn')
    },
  },
}
</script>

<template>
  <v-app-bar app color="white" class="header" flat>
    <v-container class="header-container">
      <v-row>
        <v-col class="d-flex align-center">
          <router-link
            to="/Dashboard"
            active-class=""
            class="header-button px-0 wallid-logo"
            text
          >
            <v-img
              alt="Vuetify Logo"
              class="shrink mx-2"
              contain
              src="../assets/idefoto-logo.png"
              transition="scale-transition"
              width="150"
            />
          </router-link>
        </v-col>

        <v-spacer />
        <ProductTourTooltip direction="bottom" :show="step === 0">
          <router-link to="/Dashboard" prop="button" class="header-button" text>
            <DashBoardIcon />
            <span class="mx-2">{{ $t('header.dashboard') }}</span>
          </router-link>
        </ProductTourTooltip>
        <ProductTourTooltip direction="bottom" :show="step === 1">
          <router-link
            :to="{ path: '/IssuerProfile' }"
            prop="button"
            class="header-button"
            text
          >
            <IssuingEntityIcon />
            <span class="mx-2">{{ $t('header.issuing') }}</span>
          </router-link>
        </ProductTourTooltip>
        <!-- <ProductTourTooltip :direction="'bottom'" :show="step == 2">
          <router-link to="/Settings" prop="button" class="header-button " text>
            <SettingsIcon />
            <span class="mx-2">{{ $t('header.settings') }}</span>
          </router-link>
        </ProductTourTooltip> -->

        <div
          active-class=""
          class="header-button"
          text
          @click="signOut()"
        >
          <v-img
            alt="Vuetify Logo"
            class="shrink mx-4"
            contain
            src="../assets/SignOut.png"
            transition="scale-transition"
            width="25"
          />
          <span class="mx-2">{{ $t('header.signOut') }}</span>
        </div>
        <div class="tutorial-step5">
          <ProductTourTooltip direction="top" :show="step === 4" />
        </div>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<style lang="scss">
.dca-verify-web .v-app-bar.header {
  margin: 0;
  .tutorial-step5 {
    position: fixed;
    right: 20rem;
    top: 20px;
  }
  .beta_logo {
    border-radius: 3px;
    background-color: #f84563;
    padding: 2px 7px;
    p {
      font-size: 10px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--white);
      margin-bottom: 0;
    }
  }
  span[role='button'] {
    padding: 12px 0;
  }
  .v-toolbar__content {
    .header-container {
      // padding: 0 12px;
      // width: 100%;
      // display: flex;
      max-width: 1194px;
    }
  }
  .header-button {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: var(--charcoal-grey);

    text-decoration: none;
    display: flex;
    align-self: center;
    // &:last-child {
    //   margin-right: -24px;
    // }

    &:not(.wallid-logo) {
      padding: 22px 8px;
    }
    span {
      margin-top: -2px;
    }
    &:hover:not(.wallid-logo) {
      color: var(--teal-blue);
      position: relative;
      svg {
        g {
          fill: var(--teal-blue);
        }
      }
    }
    &.router-link-active {
      color: var(--teal-blue);
      position: relative;

      &:after {
        content: '';
        position: absolute;
        height: 3px;
        background: #0ea4b5;
        bottom: -2px;
        left: 0px;
        width: calc(100% - 0px);
      }
      svg {
        g {
          fill: var(--teal-blue);
        }
      }
    }
  }
}
</style>
