import axios from '../../api/axios'
import { SIGN_IN, SIGN_OUT } from '../actions'

const ACCESS_TOKEN = 'accessToken'
const WALLET_ADDRESS = 'walletAddress'

const state = {
  accessToken: sessionStorage.getItem(ACCESS_TOKEN) || '',
  walletAddress: sessionStorage.getItem(WALLET_ADDRESS) || '',
  email: '',
}

const mutations = {
  setAccessToken: (state, value) => {
    state.accessToken = value
    if (value) {
      sessionStorage.setItem(ACCESS_TOKEN, value)
    }
  },
  setWalletAddress: (state, value) => {
    state.walletAddress = value
    if (value) {
      sessionStorage.setItem(WALLET_ADDRESS, value)
    }
  },
  setUserEmail: (state, value) => {
    state.email = value
  },
}

const actions = {
  [SIGN_IN]: async ({ commit, rootState }, loginData) => {
    // console.log('SIGN IN: ', loginData)
    try {
      const { data, status } = await axios.post('/auth/login', {
        username: loginData.username,
        password: loginData.password,
      })
      if (status !== 200 || !data.token) {
        throw new Error('Invalid credentials')
      }

      commit('setAccessToken', data.token)
      commit('setWalletAddress', data.walletAddress)
      commit('setUserEmail', data.email)
    }
    catch (err) {
      rootState.debug('SIGN IN error: ', err.response)
      sessionStorage.removeItem(ACCESS_TOKEN) // if the request fails, remove any possible user token if possible
      sessionStorage.removeItem(WALLET_ADDRESS)
      if (err.response.data.errorStatus) {
        throw new Error(err.response.data.errorStatus)
      }
      throw err
    }
  },
  [SIGN_OUT]: ({ commit, rootState }) => {
    rootState.debug('Action SIGN_OUT')
    return new Promise((resolve) => {
      commit('setAccessToken')
      commit('setWalletAddress')
      commit('setUserEmail')

      sessionStorage.removeItem(ACCESS_TOKEN) // clear your user's token from sessionStorage
      sessionStorage.removeItem(WALLET_ADDRESS)
      resolve()
    })
  },
}

const getters = {
  accessToken: state => state.accessToken,
  walletAddress: state => state.walletAddress,
  userEmail: state => state.email,
}

export default {
  // namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
