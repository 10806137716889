<script>
// import { mapGetters } from "vuex";

import { SIGN_IN } from '../store/actions'

import AppFooter from '../layout/AppFooter'

import sideImage from '../assets/wallid-background.jpg'

export default {
  name: 'SignIn',
  title: 'pageTitle.signIn',
  components: {
    AppFooter,
  },
  data() {
    return {
      card: undefined,
      sideImage,
      username: '',
      password: '',
      usernameRules: [
        v => !!v || this.$t('signin.username'),
      ],
      passwordRules: [
        v => !!v || this.$t('signin.password'),
      ],
      error: undefined,
    }
  },
  computed: {},
  mounted() {
    this.debug(`*** ${this.$options.name} ***`)
    this.$store.commit('isLoading', false)
  },
  methods: {
    async signIn() {
      console.log('Sign in')
      try {
        // remove white spaces from username
        this.username = this.username.trim()
        await this.$store.dispatch(SIGN_IN, { username: this.username, password: this.password })
        console.log('Sign in stopOnboarding')
        this.$router.push('/Dashboard')
      }
      catch (error) {
        console.log('Sign in error', error)
        this.error = this.$te(`error.${error?.message}`) ? this.$t(`error.${error?.message}`) : this.$t('error.default')
        // this.$router.push('/SignUp')
      }
    },
  },
}
</script>

<template>
  <v-container class="signin pa-0">
    <v-row>
      <v-col cols="5" class="pt-8 pb-8" style="padding-left: 10vw">
        <v-col cols="12" class="pt-8 pb-10 pl-9">
          <div class="d-flex align-center">
            <v-img
              alt="Vuetify Logo"
              class="shrink mr-2"
              contain
              src="../assets/idefoto-logo.png"
              transition="scale-transition"
              width="160"
            />
          </div>
        </v-col>
        <v-col cols="12" class="pt-16 pb-5 pl-9">
          <h1 class="title_header">
            {{ 'Uruguay Digital ID' }}
          </h1>
        </v-col>
        <v-col cols="8" class="pt-1 pr-10 pl-9">
          <v-form @submit.prevent="signIn">
            <v-text-field
              v-model="username"
              :rules="usernameRules"
              :label="$t('signin.username') "
              required
              aria-autocomplete="username"
              color="#c7eaee"
            />
            <v-text-field
              v-model="password"
              :rules="passwordRules"
              :label="$t('signin.password')"
              required
              type="password"
              aria-autocomplete="password"
              color="#c7eaee"
            />
            <p class="error--text">
              {{ error }}
            </p>
            <v-btn
              text
              class="advance-btn"
              type="submit"
            >
              {{ $t('signin.button') }}
            </v-btn>
          </v-form>
        </v-col>
      </v-col>
      <v-col cols="7" class="pt-0">
        <v-img
          alt="Wallid Background"
          class="shrink mr-2"
          contain
          src="../assets/wallid-background.jpg"
          transition="scale-transition"
          min-width="864"
          min-height="100"
        />
      </v-col>
    </v-row>
    <AppFooter />
  </v-container>
</template>
