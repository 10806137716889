<script>
// import { mapGetters } from "vuex";
import axios from 'axios'
import AppFooter from '../layout/AppFooter'

export default {
  name: 'Invite',
  title: 'pageTitle.invite',
  components: {
    AppFooter,
  },

  data() {
    return {
      buttonText: 'Get Invite',
      loading: false,
      email: null,
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.debug(`*** ${this.$options.name} ***`)
  },
  mounted() {
    this.$store.commit('isLoading', false)
  },
  unmounted() {},
  methods: {
    sendEmail() {
      this.loading = true
      const email = this.email

      const nameMatch = email.match(/^([^@]*)@/)
      const name = nameMatch ? nameMatch[1] : null

      const BASE_URL = `${process.env.VUE_APP_API_URL}/admin/invite-demo`

      const body = {
        email,
        name,
      }
      axios
        .post(BASE_URL, body)
        .then((res) => {
          console.log(res)
          this.buttonText = 'Invite Sent!'
          this.loading = false
          setTimeout(() => {
            this.buttonText = 'Get Invite'
          }, 2000)
        })
        .catch((error) => {
          this.loading = false
          console.error(error)
        })
    },
  },
}
</script>

<template>
  <v-container class="invite-page grey-background">
    <v-row justify="center">
      <v-col cols="12" class="pt-8 pb-8 pl-9 grey-background">
        <div class="d-flex align-center justify-center">
          <v-img
            alt="Wallid Logo"
            class="shrink mr-1"
            contain
            src="../assets/logo-credential-issuers.png"
            transition="scale-transition"
            width="130"
          />
          <v-img
            alt="Vuetify Logo"
            class="shrink mx-2"
            contain
            src="../assets/idefoto-logo.png"
            transition="scale-transition"
            width="113"
          />
        </div>
      </v-col>
    </v-row>
    <v-row class="shadow" justify="center" style="">
      <v-col cols="12" md="8" class="pt-8 justify-center">
        <h1
          class="title_header text-center"
          style="color: #115a5f; font-size: 22px; font-weight: 500"
        >
          Be the first to create a decentralised Identity infrastructure <br>
          for your community
        </h1>
      </v-col>
      <v-col cols="6" class="pb-0 pl-md-9 d-none d-sm-flex">
        <v-img :src="require('../assets/illustration-features.png')" />
      </v-col>
      <v-col cols="12" md="6" class="pt-16 pb-0 pl-md-9">
        <ul>
          <li
            class="subtitle_header mb-9"
            style="
              color: #115a5f;
              font-size: 16px;
              font-weight: 500;
              line-height: 30px;
            "
          >
            Create Identities and certificates and issue them to your community
            members' web3 wallets
          </li>
          <li
            class="subtitle_header mb-9"
            style="
              color: #115a5f;
              font-size: 16px;
              font-weight: 500;
              line-height: 30px;
            "
          >
            Provide your email address below to get an invite in your inbox that
            will unlock WallID for Issuers platform features.
          </li>
        </ul>
        <v-form lazy-validation style="" @submit.prevent="sendEmail">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="email"
                  class="email-input"
                  :rules="emailRules"
                  required
                  flat
                  solo
                  style="margin: 0"
                />
                <v-btn
                  type="submit"
                  class="next"
                  style="width: 9rem"
                  :disabled="loading"
                >
                  {{ buttonText }}
                </v-btn>
              </v-col>
              <v-col>
                <p class="light_text" style="line-height: 20px">
                  This is a private beta and you may encounter some bugs. Your feedback
                  and suggestions are highly appreciated.
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
    <AppFooter />
  </v-container>
</template>

<style lang="scss" scoped>
.dca-verify-web.theme--light.v-application {
  background: #eeeeee !important;
}
.invite-page {
  max-width: 1194px;
  .col {
    background-color: #ffffff;
  }
  .grey-background {
    background-color: #eeeeee;
  }
  .shadow {
    box-shadow: 0 2px 4px 0 #b8b9bb;
    border-radius: 5px;
    padding: 12px;
    background-color: #ffffff;
    padding-bottom: 120px;
    @media screen and (min-width: 600px) {
      padding: 0 40px 40px 40px;
    }
  }
  ul {
    list-style-position: outside;
  }
  .v-form {
    display: flex;
  }
  .next.v-btn {
    width: 20%;
    height: 48px !important;
    padding: 6px 12px !important;
  }
  .email-input {
    min-width: 70%;
    .v-input__slot {
      border: solid 1px var(--teal-blue);
    }
    .v-text-field__details {
      position: relative;
      top: -75px;
    }
    &.error--text {
      .v-text-field__slot {
        font-size: 16px;
      }
    }
  }
  .footer_wallid {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .stepper-signup {
    box-shadow: none;
  }
  .sign_footer {
    .links {
      font-size: 12px !important;
    }
  }
}
</style>
