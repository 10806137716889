<script>
const VERIFIER_APK_URL = process.env.VUE_APP_VERIFIER_APK_URL
const WALLET_APK_URL = process.env.VUE_APP_WALLET_APK_URL

export default {
  title: 'Instrucciones de instalación',
  data() {
    return {
      WALLET_APK_URL,
      VERIFIER_APK_URL,
    }
  },
  mounted() {
    this.$store.commit('isLoading', false)
  },

}
</script>

<template>
  <v-container class="invite-page grey-background">
    <v-row justify="center">
      <v-col cols="12" class="pt-8 pb-8 pl-9 grey-background">
        <div class="d-flex align-center justify-center">
          <v-img
            alt="Vuetify Logo"
            class="shrink mx-2"
            contain
            src="../assets/idefoto-logo.png"
            transition="scale-transition"
            width="113"
          />
        </div>
      </v-col>
    </v-row>
    <v-row class="shadow" justify="center" style="">
      <v-col cols="12" md="10" class="pt-8 justify-center">
        <h1
          class="title_header text-center mb-4"
          style="color: #115a5f; font-size: 28px; font-weight: 500"
        >
          Cómo Instalar las Aplicaciones para Ciudadanos y Autoridades:
        </h1>
      </v-col>
      <v-col cols="6" class="pb-0 pl-md-9 d-none d-sm-flex">
        <v-img contain :src="require('../assets/illustration-features.png')" />
      </v-col>
      <v-col cols="12" md="6" class="pt-6 pb-0 px-md-9">
        <div class="content">
          <h2 class="subtitle_header">
            Aplicación Billetera de Ciudadano:
          </h2>
          <div class="download-links d-flex flex-column px-3">
            <v-btn
              class="next ma-3 my-6"
              style="width: 12rem"
              :href="WALLET_APK_URL"
              target="_blank"
            >
              {{ 'Descargar la aplicación' }}
            </v-btn>
          </div>
          <ol>
            <li>
              Asegúrate de que tu teléfono Android tenga los permisos necesarios para instalar aplicaciones de fuentes externas.   <span style="text-decoration: underline;">

                Consulta las instrucciones más abajo
              </span>
            </li>
            <li>Ve a tu carpeta de Descargas e instala la aplicación.</li>
            <li>Abre la aplicación y haz clic en "Agregar Licencia de Conducir".</li>
            <li>Escanea el código QR o pega el código enviado a tu correo electrónico para agregar la Licencia de Conducir.</li>
            <li>Verifica los detalles de la Licencia de Conducir y compártela con las autoridades para su verificación.</li>
          </ol>

          <h2 class="subtitle_header mt-14">
            Aplicación Verificadora de Policía:
          </h2>
          <div class="download-links d-flex flex-column px-3">
            <v-btn
              class="back ma-3 my-6"
              style="width:12rem"
              :href="VERIFIER_APK_URL"
              target="_blank"
            >
              {{ 'Descargar la aplicación' }}
            </v-btn>
          </div>
          <ol>
            <li>
              Asegúrate de que tu teléfono Android tenga los permisos necesarios para instalar aplicaciones de fuentes externas.
              <span style="text-decoration: underline;">
                Consulta las instrucciones más abajo.
              </span>
            </li>
            <li>
              Ve a tu carpeta de Descargas e instala la aplicación..
            </li>
            <li>
              Abre la aplicación y haz clic en "Agregar Licencia de Conducir"..
            </li>
            <li>
              Haz clic en el botón de Escanear para verificar las licencias de conducir de los ciudadanos a través de sus códigos QR..
            </li>
            <li>
              Verifica las Licencias de Conducir, confíscalas o cambia los puntos dentro de ella.
            </li>
          </ol>
        </div>
      </v-col>

      <v-col cols="12" md="12" class="pt-12 pb-0 px-md-9">
        <h1 class="title_header">
          Las aplicaciones se descargan desde Google Drive, por lo que necesitas tener la autorización correspondiente configurada en tu teléfono. Asegúrate de seguir estos pasos para otorgar el permiso necesario:
        </h1>
      </v-col>
      <v-col cols="12" md="12" class="pt-6 pb-0 pl-md-9">
        <div class="content">
          <h2 class="subtitle_header mb-4">
            Instrucciones para dispositivos Android 8.0 y superiores:
          </h2>
          <ol>
            <li><strong>Abrir Configuración</strong>: Dirígete a la aplicación de Configuración en tu dispositivo.</li>
            <li><strong>Seleccionar Contraseña y seguridad</strong>: Busca y selecciona la opción "Contraseña y seguridad".</li>
            <li><strong>Seguridad del sistema</strong>: Dentro de "Contraseña y seguridad", selecciona "Seguridad del sistema".</li>
            <li><strong>Fuentes de instalación</strong>: Busca y selecciona la opción "Fuentes de instalación".</li>
            <li><strong>Activar Google Drive</strong>: En la lista de aplicaciones, encuentra y activa Google Drive. Esto permitirá que las APK se descarguen e instalen desde Google Drive.</li>
          </ol>

          <h2 class="subtitle_header my-4 mt-6">
            Instrucciones para dispositivos con versiones de Android anteriores:
          </h2>
          <ol>
            <li><strong>Abrir Configuración</strong>: Dirígete a la aplicación de Configuración en tu dispositivo.</li>
            <li><strong>Seguridad</strong>: Desplázate hacia abajo y selecciona "Seguridad" o "Seguridad y privacidad" (dependiendo de la versión de Android).</li>
            <li><strong>Fuentes desconocidas</strong>: Busca la opción "Fuentes desconocidas" y actívala. Esto permitirá la instalación de aplicaciones desde orígenes distintos a Google Play Store.</li>
            <li><strong>Gestionar permisos por aplicación</strong>: Si es necesario, puedes gestionar los permisos específicos para Google Drive y otras aplicaciones desde aquí.</li>
          </ol>
        </div>
      </v-col>
    </v-row>
    <AppFooter />
  </v-container>
</template>

<style lang="scss">
.dca-verify-web.theme--light.v-application {
  background: #eeeeee !important;
}
.invite-page {
  max-width: 1600px;
  .content{
    .subtitle_header {
      font-size: 22px !important;
    }
    .title_header{
      font-size: 26px !important;
    }
    ol {
      list-style-type: decimal;
      li {
        margin-bottom: 10px;
        font-size: 18px;
      }
    }
  }
  .col {
    background-color: #ffffff;
  }
  .grey-background {
    background-color: #eeeeee;
  }
  .shadow {
    box-shadow: 0 2px 4px 0 #b8b9bb;
    border-radius: 5px;
    padding: 12px;
    background-color: #ffffff;
    padding-bottom: 120px;
    @media screen and (min-width: 600px) {
      padding: 0 40px 40px 40px;
    }
  }
  ul {
    list-style-position: outside;
  }
  .v-form {
    display: flex;
  }
  .next.v-btn {
    width: 20%;
    height: 48px !important;
    padding: 6px 12px !important;
  }
  .email-input {
    min-width: 70%;
    .v-input__slot {
      border: solid 1px var(--teal-blue);
    }
    .v-text-field__details {
      position: relative;
      top: -75px;
    }
    &.error--text {
      .v-text-field__slot {
        font-size: 16px;
      }
    }
  }
  .footer_wallid {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .stepper-signup {
    box-shadow: none;
  }
  .sign_footer {
    .links {
      font-size: 12px !important;
    }
  }
}
</style>
