<script>
// import { mapGetters } from "vuex";
import { SIGN_IN } from '../store/actions'
import AppFooter from '../layout/AppFooter'

export default {
  name: 'SignUp',
  components: {
    AppFooter,
  },
  data() {
    return {
      card: undefined,
      step: 1,
      showSign: false,
      showButton: false,
      checkOnce: true,
      numberTries: 5,
    }
  },
  computed: {},
  watch: {
    step(value) {
      console.log('after', value, this.showButton)
      this.showButton = false
    },
  },
  created() {
    this.debug(`*** ${this.$options.name} ***`)
  },
  async mounted() {
    window.addEventListener('focus', this.onFocus)
    window.addEventListener('blur', this.onBlur)
    document.addEventListener('wallid_event_ready', () => {
      this.step = 2
      this.$log.debug('Force plugin connect listener')

      try {
        window.wallid('wallid_connect', { level: 2 }).then(() => {
          console.log('connected')
          this.$store.dispatch(SIGN_IN)
          sessionStorage.setItem('WALLET_CREATED', true)
          this.$router.push('/Dashboard')
        })
      }
      catch (err) {
        console.log(err)
        this.step = 1
        sessionStorage.removeItem('WALLET_CREATED')
        // localStorage.removeItem("PLUGIN_INSTALLED");
        // this.$router.push("/SignIn");
      }
    })

    document.addEventListener('wallid_wallet_done', () => {
      this.showSign = true
    })

    console.log(localStorage.getItem('PLUGIN_INSTALLED') === 'true')

    console.log('hasWallet', hasWallet)

    if (
      localStorage.getItem('PLUGIN_INSTALLED') === 'true'
      && localStorage.getItem('REGISTRATION_IN_PROGRESS') === 'false' // true?
      && !hasWallet
    ) {
      this.step = 2
      this.$log.debug('Force plugin connect mounted')

      try {
        window.wallid('wallid_connect', { level: 2 }).then(() => {
          console.log('connected')
          clearInterval(this.t)

          this.$store.dispatch(SIGN_IN)
          sessionStorage.setItem('WALLET_CREATED', 'true')
          newOnboarding.stopOnboarding()
          this.$router.push('/Dashboard')
        })
      }
      catch (err) {
        console.log(err)
        this.step = 1
        sessionStorage.removeItem('WALLET_CREATED')
      }
    }

    if (!hasWallet)
      this.t = setInterval(() => this.checkPlugin(), 300)
  },
  unmounted() {
    window.removeEventListener('focus', this.onFocus)
    window.removeEventListener('blur', this.onBlur)
  },
  methods: {

  },
}
</script>

<template>
  <v-container class="signin pa-0">
    <v-row>
      <v-col cols="5" class="pt-8 pb-8" style="padding-left: 10vw">
        <v-col cols="12" class="pt-8 pb-8 pl-9">
          <div class="d-flex align-center">
            <v-img
              alt="Vuetify Logo"
              class="shrink mr-2"
              contain
              src="../assets/logo-credential-issuers.png"
              transition="scale-transition"
              width="103"
            />
          </div>
        </v-col>
        <v-col cols="12" class="pt-8 pb-0 pl-9">
          <p class="light_text">
            {{ $t('signup.step[0]') }} {{ step }} {{ $t('signup.step[1]') }} 2
          </p>
        </v-col>
        <v-stepper v-model="step" class="stepper-signup" elevation="0">
          <v-stepper-items>
            <v-stepper-content step="1" class="pt-0">
              <v-container class="pr-12 pt-0">
                <v-row>
                  <v-col cols="12" class="pt-2 pb-5">
                    <h1 class="title_header">
                      {{ $t('signup.steps[0].title') }}
                    </h1>
                  </v-col>
                  <v-col cols="12" class="pt-1 pr-10">
                    <p class="subtitle_text">
                      {{ $t('signup.steps[0].subtitle') }}
                    </p>
                  </v-col>
                  <v-col cols="7" class="pt-1 pr-10">
                    <!-- Remove on click, add again if user clicks
              outside plugin login and doesnt sign in  -->
                    <v-btn
                      text
                      class="advance-btn"
                      style="max-width: 230px"
                      @click="startOnboarding"
                    >
                      {{ $t('signup.steps[0].button') }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="pt-7 pb-16">
                    <p class="normal_text">
                      {{ $t('signup.steps[0].signup[0]') }}
                      <router-link to="SignIn" class="links">
                        {{ $t('signup.steps[0].signup[1]') }}
                      </router-link>
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>

            <v-stepper-content step="2" class="pt-0">
              <v-container class="pr-12 pt-0">
                <v-row v-if="!showSign">
                  <v-col cols="12" class="pt-2 pb-5">
                    <h1 class="title_header">
                      {{ $t('signup.steps[1].title') }}
                    </h1>
                  </v-col>
                  <v-col cols="12" class="pt-1 pr-16">
                    <p class="subtitle_text mb-0">
                      {{ $t('signup.steps[1].subtitle') }}
                    </p>
                  </v-col>
                  <v-col v-if="showButton" cols="7" class="py-7 pr-10">
                    <!-- Remove on click, add again if user clicks
              outside plugin login and doesnt sign in  -->
                    <v-btn text class="advance-btn" @click="createPlugin">
                      {{ $t('signup.steps[1].button') }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="pr-16 pb-16">
                    <p class="normal_text">
                      {{ $t('signup.steps[0].signup[0]') }}
                      <router-link to="SignIn" class="links">
                        {{ $t('signup.steps[0].signup[1]') }}
                      </router-link>
                    </p>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12" class="pt-2 pb-5">
                    <h1 class="title_header">
                      {{ $t('signup.steps[2].title') }}
                    </h1>
                  </v-col>
                  <v-col cols="12" class="pt-1 pr-16">
                    <p class="subtitle_text">
                      {{ $t('signup.steps[2].subtitle') }}
                    </p>
                  </v-col>
                  <v-col cols="7" class="pt-1 pr-10">
                    <!-- Remove on click, add again if user clicks
              outside plugin login and doesnt sign in  -->
                    <v-btn
                      v-if="showButton"
                      text
                      class="advance-btn"
                      @click="createPlugin"
                    >
                      {{ $t('signin.button') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
      <v-col cols="7" class="pa-0">
        <v-img
          alt="Wallid Background"
          class="shrink mr-2"
          contain
          src="../assets/wallid-background.jpg"
          transition="scale-transition"
          min-width="864"
          min-height="100"
        />
      </v-col>
    </v-row>
    <AppFooter />
  </v-container>
</template>

<style lang="scss">
.signin {
  .stepper-signup.v-stepper {
    box-shadow: none;
    background: transparent;
  }
  .sign_footer {
    .links {
      font-size: 12px !important;
    }
  }
}
</style>
