async function getFileFromUrl(url, name, defaultType = 'image/png') {
  console.log('Get File from URL', url, name)
  const response = await fetch(url)
  const data = await response.blob()

  // check if file has extension and remove # from name

  const fileName = name.includes('.') ? name : `${name}.png`.replace(/#/g, '')

  return new File([data], fileName, {
    type: response.headers.get('content-type') || defaultType,
  })
}

export { getFileFromUrl }
