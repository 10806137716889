import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store'
import util from '../plugins/util'

import i18n from '../i18n'
import { routes } from './routes'

Vue.use(VueRouter)

const debug = util.methods.debug
// function inIframe() {
//   try {
//     return window.self !== window.top;
//   } catch (e) {
//     return true;
//   }
// }
const router = new VueRouter({
  mode: 'history',
  routes,
})
router.beforeEach((to, from, next) => {
  debug('toPath', to)
  debug('accessToken', store.getters.accessToken)

  if (to.meta.i18n) {
    Object.keys(to.meta.i18n).forEach(lang =>
      i18n.mergeLocaleMessage(lang, to.meta.i18n[lang]),
    )
  }

  const invite = to.query.invite_admin

  if (invite) { localStorage.setItem('invite_admin', invite) }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.accessToken) {
      console.log('Continue')
      next()
      return
    }
    next('/SignIn')
  }
  else {
    if (to.path === '/') {
      debug('Home Path')
      return next('/Dashboard')
    }
    else {
      return next()
    }
  }
})

export default router
