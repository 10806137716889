export default {

  drivingLicense: {
    title: 'Driver\'s License',
    subtitle: 'Driver Credential',
    attributes: {
      name: 'Name',
      email: 'Email',
      expirydate: 'Expiration Date',
      nationality: 'Nationality',
      dateofbirth: 'Date of Birth',
      licenseclass: 'License Class',
      licensenumber: 'License Number',
      address: 'Address',
      country: 'Country',
      phonenumber: 'Phone Number',
      idnumber: 'ID Number',
      procedencia: 'Provenance',
      restrictions: 'Restrictions',
    },
    categories: 'Categories',

  },
  pageTitle: {
    dashboard: 'Dashboard',
    profile: 'Profile',
    issue: 'Issue Credentials',
    createCert: 'Create Credential Template',
    createCA: 'Create Credential Issuer',
    viewCredential: 'View Credentials',
    pendingTasks: 'Pending Approvals',
    editProfile: 'Edit Profile',
    invite: 'Invite Admin',
    signIn: 'Sign in',
    viewTemplate: 'View Template',
    issueCredential: 'Issue Credential',
  },
  step: ['Paso', 'de'],
  signin: {
    title: 'Create and Issue Digital ID documents',
    button: 'Sign in ',
    username: 'Username',
    password: 'Password',
  },
  error: {
    UsernameNotFoundError: 'Username not found',
    WrongUsernamePasswordError: 'Wrong username or password',
    default: 'An error occurred',
  },
  successModal: {
    design: {
      title: 'Design successfully created',
      text: 'This {designType} was successfully added to your portfolio. Access and issue them from the dashboard',
    },
  },
  dashboard: {
    title: 'Dashboard',
    subtitle: 'Your credential portfolio',
    emptyTemplate: 'Create a new credential',
    counters: {
      pending: 'Pending approvals',
      revoked: 'Revoked credentials',
      templates: 'Templates created',
      issued: 'Credentials issued',
      approved: 'Approved Credentials',
    },
  },
  profile: {
    title: 'Profile',
    linkBlockChain: 'Verify Credential Issuer on the Blockchain ',
    issuerID: 'Credential Issuer ID on the Blockchain',
    admins: {
      title: 'All admins',
      tabs: ['All admins', 'Masters', 'Managers'],
      menu: ['Add new Admin'],
    },
    credentials: {
      title: 'Credential templates',
      menu: ['Add new template', 'Delete template'],
      empty: [
        'It seems that you don\'t have any credential templates.',
        'Add a credential template',
        'now!',
      ],
    },
  },
  issue: {
    pageTitle: 'Issued Credentials',
    title: 'Issue Credentials',
    new: 'Issue Credentials',
    labelUpload: 'Upload Image',
    buttonUpload: 'Upload',
    menu: ['Issue credentials manually', 'Issue credentials in bulk'],
    wallet: 'Does the credentialed person have a MyWalliD wallet?',
    radio: ['Yes', 'No'],
    walletField: 'Credentialed person MyWalliD address',
    emailField: 'Credentialed person email address',
    emailHint:
      'An automatic email will be generated and sent so the person receiving this credential.',
    emailError: 'Please enter a valid email',
    button: 'Next',
    IssueCredentials: 'Issue credentials manually',
    IssueInBulk: 'Issue credentials in bulk',
  },
  inBulkModal: {
    title: 'Issue Credentials in bulk',
    text1:
      'Upload a file with a table containing all the members information for this credential',
    text2:
      'Make sure your file has the same atributes and in the same order as you defined in the credential template on the platform and that they are ordered from left to right in the same line on the table.',
    text3:
      'Confirm the data on the credentials to be issued and send them to all the members displayed below.',
    upload: ['Drag and drop or ', 'upload ', ' your spreadsheet'],
    buttonNext: 'Next',
    buttonIssue: 'Issue credentials',
    buttonDownload: 'Download excel template',
    error:
      'There are attributes within the excel document that doesn’t match with the attributes from your credential template.',
    errorFile:
      'We don\'t accept this file format. Please upload an CSV spreadsheet format.',
    hint: 'We only accept CSV spreadsheet format',
  },

  // NEW TEXT
  credentialDesign: {
    title: 'Create a new credential ',
    subtitle: ['Choose a preset design', 'Create your design'],
    text: [
      'Sed ut de voluptate et inter mediocrem animadversionem atque admonitionem.',
      'In oculis quidem faciunt, ut summo bono, dolorem aspernari ut ita ruant itaque.',
    ],
    buttons: ['Choose preset design', 'Create design'],
    uploadLogo: 'Upload Logo',
    addDesignButton: 'Add design',
    preset: {
      ChoosePresetTemplate: 'Choose a preset design',
      subtitle: 'Select one type of credential:',
      types: ['Badge', 'Certificate', 'Card'],
      label: {
        Badge: 'Name this badge:',
        Certificate: 'Name this certificate:',
        Card: 'Name this card:',
      },
      photoLabel: {
        Badge: 'Upload the logo you want to appear on the badge',
        Certificate: 'Upload the logo you want to appear on the certificate',
        Card: 'Upload the logo you want to appear on the card',
      },
    },
  },
  // END NEW TEXT
  createCertModal: {
    title: 'Add new credential template',
    upload: [
      'Upload Credential Issuer Logo',
      'Max. file size: 5 MB',
      'JPG or PNG only',
    ],
    errorUrlPhoto: 'Please upload a photo',
    uploadButton: 'Upload photo',
    maxImages:
      'It’s not possible to add more than 3 images to this credential template',
    name: 'Credential template name',
    placeholder: 'Type a template name',
    errorCertName: 'Please add a name for your credential template',
    radio: ['Credential template type', 'Front side', 'Front and back side'],
    front: 'Front side',
    back: 'Back side',
    frontSide: {
      add: ['Add attributes to your credential:', 'Add'],
      dropdown: ['Attribute', 'Input type'],
      placeholder: [
        'Select an attribute',
        'Select an input type',
        'Type an attribute',
      ],
      mandatory: 'Mandatory',
    },
    signatures: {
      add: ['Add signatures to your credential template:', 'Add'],
      dropdown: ['Name', 'Role', 'Signature type'],
      hint: 'Only type the first and last name',
      placeholder: ['Type a name', 'Type a role'],
      maxSigs:
        'It’s not possible to add more than 2 signatures to this credential template',
      error: 'Please add atleast one signature',
    },
    backSide: {
      type: 'Attribute type',
      add: ['Add columns to your table: ', 'Add'],
      dropdown: ['Column name', 'Type', 'Input type'],
      placeholder: [
        'Select a column name',
        'Select an input type',
        'Type a column name',
      ],
      maxColumns:
        'It’s not possible to add more than 3 columns to this credential template',
    },
    errorModule: 'Please add a module',
    errorType: 'Please add a type',
    errorAttribute: 'Please add an attribute',
    errorInput: 'Please add an input type to the attribute',
    publicField: ['Public Attribute', 'Select public attribute'],
    errorPublicField:
      'Please choose a public attribute. It won\'t be visible anywhere else but will help you identify this credential amongst all others in your credentials table',
    hint: 'The public attribute will be visible by anyone accessing the platform and will act as the identifier for this credential.',
    card: 'Confirm credential layout and if the attributes are rightly configured.',

    hintCard:
      'Once you create this credential template you won\'t be able to come back and edit it.',
  },
  createCAModal: {
    title: 'Welcome to WalliD for Credential Issuers',
    subtitle: 'Create your Credential Issuer entity on the Blockchain',
    caName:
      'Provide the name of your institution or community in order to generate a unique ID on the blockchain:',
    email: 'E-mail',
    hint: 'Make sure it is a publicly recognised name since it will be associated to every credential you will issue.',
    errorCaName: 'Please add a name to this Credential Issuer entity',
    errorEmail: 'Please add an valid email',
  },
  cancelModal: {
    title: 'Cancel Credencial',
    text: 'Are you sure you want cancel this credential? <br> You won\'t be able to undo this action.',
  },
  revokeModal: {
    title: 'Revoke Credential',
    text: 'Are you sure you want revoke this credential? <br> You won\'t be able to undo this action.',
    button: 'Revoke Credential',
  },
  viewCredential: {
    tabs: ['All credentials', 'Pending', 'Approved', 'Revoked'],
    approved: 'It seems that you don\'t have any approved credentials yet.',
    pending: 'No pending approvals.',
    revoked: 'No revoked credentials.',
    noCredentials: [
      'It seems like you haven\'t issued any credentials yet.',
      'Issue your first credential',
      'now!',
    ],
    modal: {
      title: 'View credential',
    },
    noPreview: 'No preview available at the moment',
  },
  pendingTasks: {
    title: 'Pending approvals',
    tabs: ['Pending Credentials ', 'Approved '],
    emptyTable: 'It looks like you don\'t have any pending approvals for now',
  },
  header: {
    dashboard: 'Dashboard',
    issuing: 'Credential Issuer Profile',
    settings: 'Settings',
    signOut: 'Sign out',
  },
  footer: {
    language: 'Language:',
    contacts: 'Contact us',
  },
  table: {
    search: 'Search by name or Credential ID',
    searchAdmin: 'Search by name or role',
    roles: {
      MASTER: 'Master',
      MANAGER: 'Manager',
    },
    noData: 'No matching records found',
    menu: {
      view: 'View credential',
      download: 'Download credential',
      approve: 'Approve credential',
      revoke: 'Revoke credential',
      cancel: 'Cancel credential',
      all: 'Approve All',
    },
  },
  status: {
    waiting_wallet: 'Waiting for wallet',
    pending_approval: 'Pending approval',
    active: 'Approved',
    revoke: 'Revoked',
    pending: 'Pending',
    activeA: 'Active',
  },
  links: {
    copyright: 'All Rights Reserved. IDEFOTO® WalliD®',
    terms: 'Terms and conditions',
    privacy: 'Privacy Policy',
  },
  button: {
    createCA: 'Create',
    add: 'Add another row',
    next: 'Next',
    back: 'Back',
    continue: 'Continue',
    create: 'Add template',
    issue: 'Issue credential',
    confirm: 'Confirm',
    cancel: 'Cancel Credential',
    download: 'Download excel template',
  },
  tooltips: [
    {
      title: 'Dashboard',
      text: 'An overview for the Credential Issuer entity activity. Access and complete all pending tasks from this dashboard.',
    },
    {
      title: 'Credential Issuer profile',
      text: 'Manage Credential Issuer templates, admins and permissions.',
    },
    {
      title: 'Settings',
      text: 'Access your account and check permissions for this Credential Issuer entity',
    },
    {
      title: 'Pending approvals tab',
      text: 'Manage quick and pending tasks such as approving or revoking issued credentials.',
    },
    {
      title: 'Credential templates',
      text: 'Access and create credential templates for this Credential Issuer entity.',
    },
    {
      title: 'Sign out',
      text: 'Go to MyWalliD wallet plug-in through the icon on your browser to sign out.',
    },
  ],
  errorMsg: 'An error as occurred:',
  editProfile: {
    title: 'Edit details',
    save: 'Save Changes',
  },
  modals: {
    confirm: {
      title: 'Confirm template creation',
      text: 'Are you sure about the attributes and the look of the credential you designed? Once you create this template you won’t be able to come back and edit it.',
      cancel: 'Cancel',
      confirm: 'Confirm',
    },
    storing: {
      title: 'Issuing credentials',
      text: 'Wait a moment while we are issuing your credentials',
    },
    credentials: {
      title: 'Credentials successfully sent',
      text: 'Your members have received an email with your issued credential and will now setup a MyWalliD wallet and store it in it. Once that is process is completed, you will be notified to approve the issuance and they will be able to use it anywhere online.',
    },
    email: {
      title: 'Credential issued successfully!',
      text: 'The intended user has received the credential in his email inbox. Once he has have done so, he will be able to share the credential and enable third party verification features',
    },
    credential: {
      title: 'Credential successfully sent',
      text: 'Your members have received an email with your issued credential and will now setup a MyWalliD wallet and store it in it. Once that is process is completed, you will be notified to approve the issuance and they will be able to use it anywhere online.',
    },
    successCreateTemplate: {
      title: 'Template successfully created',
      text: 'Your credential template was successfully created. Now you are ready to start issue credentials on your dashboard page.',
    },
  },
  tour: {
    title: [
      'Dashboard',
      'Credential Issuer profile',
      // 'Settings',
      'Pending approvals tab',
      'Credential templates',
      'Sign out',
    ],
    text: [
      'An overview on your Credential Issuer Entity activity. Access and complete all pending tasks from this dashboard.',
      'Manage your Credential Issuer Entity templates, admins and permissions.',
      // 'Access your account and check your permissions for this Credential Issuer Entity.',
      'Manage your quick and pending tasks such as approving or revoking issued credentials.',
      'Access and create credential templates for this Credential Issuer Entity.',
      'Go to MyWalliD wallet plugin through the icon on your browser to sign out.',
    ],
    skip: 'skip tour',
    next: 'next',
    finish: 'finish',
  },
  tabs: {
    Templates: 'Templates',
    Attributes: 'Attributes',
    Images: 'Images',
    Signatures: 'Signatures',
  },
  canvas: {
    clearPage: 'Clear page',
    deletePage: 'Delete page',
    addBackside: 'Add backside',
  },
  page: {
    delete: 'Delete',
    edit: 'Edit',
    qrcode:
      'A QR code will be generated for <br> each credential issued by you in <br> order to be verified by any third  <br> party in PDF format.',
    qrcodePalceholder: 'VERIFY HERE',
  },
  templates: {
    title: 'CHOOSE A PRESET',
    uploadDesign: 'UPLOAD YOUR DESIGN',
    textLine1: 'Your design should fit a vertical A4 sheet of paper dimensions',
    textLine2:
      'Text that will change from credential to credential shouldn\'t be in the uploaded design.',
    textLine3:
      'After uploading your design, you can use the Text tab to insert dynamic text boxes that will change from credential to credential',
    buttonFront: 'Front side',
    buttonBack: 'Back side',
  },
  attributes: {
    title: 'ADD ATTRIBUTES',
    textLine1:
      'Attribute class - inputs with this format will be printed on every credential issued for this template.',
    textLine2:
      '[ATTRIBUTEFIELD] - inputs with this format (saques brackets and uppercase) can be changed for every credential issued.',
    textLine3: 'Eg: Course - [COURSENAME]',
    buttonAdd: 'Add Attribute',
    added: 'Added attributes',
    attributeName: 'Attribute name',
    mandatory: 'Mandatory',
    tooltip:
      ' Mandatory text fields will be required to be filled when issuing a credential.',
    noDynamicAttr:
      'You don\'t have any attributes added to this template. To continue and then issue this credential you need to add at least one attribute',
  },
  images: {
    title: 'UPLOAD IMAGES',
    buttonUpload: 'Upload images',
    uploaded: 'Your uploaded images',
  },
  signatures: {
    title: 'ADD SIGNATURES',
    buttonAddSignature: 'Add text signature',
    titleImage: 'UPLOAD IMAGE WITH SIGNATURE',
    buttonUpload: 'Upload image',
    uploaded: 'Your uploaded signature images',
  },
}
